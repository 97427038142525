export const Base_Url = process.env.REACT_APP_API_BASE_URL;
export const Admin_Base_URL = process.env.REACT_APP_ADMIN_BASE_URL;
export const Hiver_Base_Url = process.env.REACT_APP_HIVER_API_BASE_URL;
export const App_Language = "EN";
export const App_Version = "1.0.0";

export const apiRoutes = {
  submitQuery: "submit-query",
  categories: "category",
  getArticlesInCategory: "articles",
  getParticulatArticle: "article",
  articleSearch: "search",
  hiverGetToken: "get-token",
  OAuthComplete: 'gx/ext/login/completeLogin',
  newHiverLoginInit: 'gx/ext/login/init',
  availableFeatures: "available-features",
};
