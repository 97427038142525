import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import CategoryWidget from "./CategoryWidget";
import { CategoryContext, KBContext } from "../../Context";
import { Link } from "react-router-dom";
import nocatImage from "../../images/nocatImage.svg";
import { useLocation } from "react-router-dom";
import ScrollToTop from "../../Components/ScrollToTop";
import { handlePageVisitData } from "../../utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
`;

const CategoryTitle = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: ${(props) => props.color};
  margin: 88px 0 54px 0;

  @media screen and (max-width: 800px) {
    margin: 60px 0 54px 0;
  }

  @media screen and (max-width: 500px) {
    margin: 44px 0 25px 0;
  }

  transition: margin 1s;
`;

const CategoryGrid = styled.div`
  display: grid;
  gap: 40px 40px;
  border-radius: 10px;
  grid-template-columns: ${(props) =>
    props.categoryCound === 1
      ? "auto"
      : props.categoryCound === 2
      ? "auto auto"
      : props.categoryCound >= 3
      ? "auto auto auto"
      : ""};
  justify-items: center;
  align-items: center;
  margin-bottom: 40px;

  @media (max-width: 1258px) {
    grid-template-columns: ${(props) =>
      props.categoryCound === 1
        ? "auto"
        : props.categoryCound >= 2
        ? "auto auto"
        : ""};
  }

  @media screen and (max-width: 1100px) {
    margin: 0px 90px 40px;
  }

  @media screen and (max-width: 800px) {
    margin: 0px 0px 40px 0px;
    grid-template-columns: auto;
  }

  @media screen and (max-width: 500px) {
    margin: 0px 0px 40px 0px;
    grid-template-columns: auto;
  }
`;

const CategoryFooter = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  color: ${(props) => props.color};
  cursor: pointer;
  font-family: Poppins;
  margin-top: 40px;
  margin-bottom: 40px;

  &:hover {
    text-decoration: underline;
  }
`;

const CategoryNotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CategoryNotFoundImage = styled.img`
  width: 132px;
  height: 101.82px;
  margin-bottom: 10px;
`;

export default function CategoryHome() {
  const { categoryList, setSelectedCategory } = useContext(CategoryContext);
  const { data, data: { isInternal } } = useContext(KBContext);
  const [isMore, setIsMore] = useState(false);

  const { search, pathname } = useLocation();

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    handlePageVisitData(window.location, isInternal);
    document.getElementById("mainWrapper")?.focus();
  }, [pathname]);

  return (
    <Wrapper>
      <ScrollToTop />
      <CategoryTitle color="rgba(49,60,75,1)">Categories</CategoryTitle>
      {categoryList?.length === 0 && (
        <CategoryNotFoundWrapper>
          <CategoryNotFoundImage src={nocatImage} />
          There are no published categories or articles
        </CategoryNotFoundWrapper>
      )}
      <CategoryGrid categoryCound={categoryList?.length}>
        {categoryList?.map((category, index) => {
          if (index > 5 && !isMore) {
            return "";
          }
          return (
            <Link
              style={{ textDecoration: "none" }}
              key={category.slug}
              to={`/${category.slug}` + search}
              onClick={() => handleCategorySelect(category)}
            >
              <CategoryWidget
                color={data?.brandColor}
                icon={category?.icon}
                title={category.title}
                description={category.description}
              />
            </Link>
          );
        })}
      </CategoryGrid>
      {!isMore && categoryList?.length > 6 ? (
        <CategoryFooter
          onClick={() => setIsMore(true)}
          color={data?.brandColor}
        >
          View more categories +
        </CategoryFooter>
      ) : null}
    </Wrapper>
  );
}
