import axios from "axios";
import { Base_Url, Admin_Base_URL, apiRoutes } from "./Constants/ApiRoutes";
import { buildParamString } from "./utils";
// Set config defaults when creating the instance
const axiosInstance = axios.create({
  credentials: "include",
  withCredentials: true,
  baseURL: Base_Url + "v1/",
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
      }

      if (error.response.status === 409) {
        console.log(error.response.data.error.message);
      }

      if (error.response.status === 500) {
        console.log(error.response.data.error.err.errors[0].message);
      }
    }

    return Promise.reject(error);
  }
);

export const getRequest = (url, payload, pathParams = {}) => {
  let config = {
    params: {
      ...payload,
    },
  };
  const paramString = buildParamString(pathParams);
  return axiosInstance.get(url + paramString, config);
};

export const postRequest = (url, payload, params) => {
  return axiosInstance.post(url, payload, { params: params });
};

export const putRequest = (url, payload, params) => {
  return axiosInstance.put(url, payload, { params: params });
};

export const deleteRequest = (url, bodyPayload, urlPayload) => {
  let config = {
    params: {
      ...urlPayload,
    },
    data: Array.isArray(bodyPayload) ? [...bodyPayload] : { ...bodyPayload },
  };
  return axiosInstance.delete(url, config);
};

export const getToken = (payload) => {
  let config = {
    baseURL: Admin_Base_URL,
    params: {
      ...payload,
    },
  };
  return axiosInstance.get(apiRoutes.hiverGetToken, config);
};

export const getUserDetails = (token) => {
  const authToken = !!token ? `Bearer ${token}` : "";
  const requestHeaders = {
    "Authorization": authToken,
  }
  return axiosInstance.get("user-details", { headers: requestHeaders });
};

export const getAvailableFeatures = (payload, pathParams = {}) => {
  let config = {
    params: {
      ...payload,
    },
  };
  const paramString = buildParamString(pathParams);
  return axiosInstance.get(apiRoutes.availableFeatures + paramString, config);
};
