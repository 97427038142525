import React, { useContext, useEffect } from "react";
import CategoryHome from "../../Components/Categories/CategoryHome";
import { SearchContext, KBContext, AutherContext } from "../../Context";
import ArticleSearch from "../../Components/ArticleSearch/ArticleSearch";
import Layout from "../../Components/Layout";
import { CategoryLoader } from "../../Components/Loaders/CategoryLoader";
import { handleGainSightEventForPageNotFound } from "../../gainSightIntegration";
import useNavigateWithSearch from "../../Context/KBContext/hooks/useNavigateWithSearch";

export default function Categories() {
  const navigate = useNavigateWithSearch();
  const { searchList, searchInput } = useContext(SearchContext);
  const { data } = useContext(KBContext);
  const { isInternal } = data;
  const { redirectToken, hasPermission } = useContext(AutherContext);

  const showCategories = () => {
    if (isInternal !== undefined && !isInternal) {
      return true;
    } else {
      return hasPermission;
    }
  }

  useEffect(() => {
    if (!data) {
      handleGainSightEventForPageNotFound(window.location.href);
      navigate("/not-found");
    }
  }, [data, navigate]);

  useEffect(() => {
    if (!redirectToken && isInternal && !hasPermission) {
      localStorage.clear();
      navigate("/login");
    }
  }, [isInternal, hasPermission, redirectToken, navigate]);


  if (showCategories() && Object.keys(data).length > 0) {
    return (
      <>
        <Layout>
          {searchInput?.length > 0 && searchList ? (
            <ArticleSearch />
          ) : (
            <CategoryHome />
          )}
        </Layout>
      </>
    );
  } else {
    return <CategoryLoader />;
  }
}
