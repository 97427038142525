import { useState, useEffect, useContext } from "react";
import { CategoryContext, KBContext } from "../index";
import { getState, setState } from "../../Constants/LocalStorage";
import { getCategory } from "../../Service/CategoryService";
import { useSearchParams } from "react-router-dom";

export default function CategoryProvider(props) {
  const { data } = useContext(KBContext);
  const [searchParams] = useSearchParams();
  const productParam = searchParams.get('product');

  const [categoryData, setCategoryData] = useState(
    getState("categoryData") ? getState("categoryData") : []
  );

  useEffect(() => {
    if (Object.keys(data)?.length > 0) {
      const pathParams = {
        ...(productParam && { product: productParam })
      };
      getCategory(pathParams).then((res) => {
        setCategoryData([...res?.data?.data.categories]);
      });
    }
  }, [data, productParam]);

  const [selectedCategory, setSelectedCategory] = useState(
    getState("selectedCategory") ? getState("selectedCategory") : false
  );

  useEffect(() => {
    setState("categoryData", categoryData);
  }, [categoryData]);

  useEffect(() => {
    setState("selectedCategory", selectedCategory);
  }, [selectedCategory]);

  return (
    <CategoryContext.Provider
      value={{
        categoryList: categoryData,
        updateCategoryList: setCategoryData,
        selectedCategory: selectedCategory,
        setSelectedCategory: setSelectedCategory,
      }}
    >
      {props.children}
    </CategoryContext.Provider>
  );
}
