import React, { useContext } from "react";
import styled from "styled-components";
import {
  Label,
  StyledButton,
} from "../Components/StyledComponents/StyledComponents";
import PoweredbyHiver from "../images/PoweredbyHiver.svg";
import { KBContext } from "../Context";
import Header from "../Components/Header";
import { CategoryLoader } from "../Components/Loaders/CategoryLoader";
import CountdownComponent from "../Components/CountdownComponent";
import useNavigateWithSearch from "../Context/KBContext/hooks/useNavigateWithSearch";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
`;
const MainWrapper = styled.div`
  box-sizing: border-box;
  max-height: 583px;
  width: 464px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  margin: 0 auto;
  margin-top: -200px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 464px;
    height: auto;
  }
  @media (max-width: 425px) {
    width: 350px;
    height: auto;
  }
`;

const Tittle = styled.h1`
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  margin: 0;
  padding: 0;
  margin-top: 12px;
`;

const ButtonWrapper = styled.div`
  margin: 30px;
`;

const FooterWrapper = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
`;

const FooterImage = styled.img``;

const Heading = styled.h1`
  font-size: 125px;
  font-style: normal;
  font-weight: 400;
  line-height: 188px;
  margin: 0;
  padding: 0;
  color: ${(props) => props.color};
  margin-top: 95px;
`;

const BannerWrapperImage = styled.div`
  width: 100%;
  height: 296px;
  background: url(${(props) => props.image}) no-repeat;
  background-size: cover;
`;

const BannerWrapperColor = styled.div`
  width: 100%;
  height: 296px;
  background-color: ${(props) => props.color};
`;

export default function NoPageFound() {
  const navigate = useNavigateWithSearch();
  const { data, showCategoryLoader } = useContext(KBContext);
  const Image_Base_Url = process.env.REACT_APP_IMAGE_BASE_URL;
  return (
    <>
      {!showCategoryLoader ? (
        <>
          <Wrapper>
            <Header bannerData={data} />
            {data?.bannerImage && data?.bannerImage !== "" ? (
              <BannerWrapperImage image={Image_Base_Url + data?.bannerImage} />
            ) : (
              <BannerWrapperColor
                color={
                  data?.brandColor ? data?.brandColor : "rgba(20, 115, 230, 1)"
                }
              />
            )}
            <MainWrapper>
              <Heading
                color={
                  data?.brandColor ? data?.brandColor : "rgba(20, 115, 230, 1)"
                }
              >
                404
              </Heading>
              <Tittle>
                {data?.errorPageText
                  ? data?.errorPageText
                  : "Oops! Page not found"}
              </Tittle>
              {!data && (
                <Label className="tooltipLabel">
                  We can’t seem to find the page you are looking for
                </Label>
              )}
              {data && <CountdownComponent />}
              <ButtonWrapper>
                {data && (
                  <StyledButton
                    textColor={data?.textColor}
                    color={data?.brandColor}
                    submit="true"
                    onClick={() => navigate("/")}
                    primary
                  >
                    Home
                  </StyledButton>
                )}
              </ButtonWrapper>
            </MainWrapper>
          </Wrapper>
          <FooterWrapper>
            <FooterImage alt="poweredbyhiver" src={PoweredbyHiver} />
          </FooterWrapper>
        </>
      ) : (
        <CategoryLoader />
      )}
    </>
  );
}
