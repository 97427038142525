import React, { useContext } from "react";
import ArticleListing from "../../Components/Articles/ArticleListing";
import {
  ArticleContext,
  CategoryContext,
  KBContext,
} from "../../Context/index";
import useNavigateWithSearch from "../../Context/KBContext/hooks/useNavigateWithSearch";

export default function ArticleList() {
  const { articleData, setSelectedArticle } =
    useContext(ArticleContext);
  const { data } = useContext(KBContext);
  const { selectedCategory } = useContext(CategoryContext);
  const navigate = useNavigateWithSearch();

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
    navigate(`/${selectedCategory.slug}/${article.slug}`);
  };

  return (
    <ArticleListing
      kbdata={data}
      selectedCategory={selectedCategory}
      articles={articleData}
      handleArticleClick={handleArticleClick}
    />
  );
}
