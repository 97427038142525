import "./App.css";
import CategoryProvider from "./Context/CategoryContext/CategoryContext";
import ArticleProvider from "./Context/ArticleContext/ArticleContext";
import KBProvider from "./Context/KBContext/KBContext";
import SearchContext from "./Context/SearchContext/SearchContext";
import AutherProvider from "./Context/AutherContext/AutherContext";
import ToastProvider from "./Context/ToastContext/ToastContext";
import Home from "./pages/Home/Home";
import { ToastContainer } from "./Components/StyledComponents/Toast";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ToastProvider>
          <ToastContainer />
          <KBProvider>
            <AutherProvider>
              <CategoryProvider>
                <ArticleProvider>
                  <SearchContext>
                    <Home />
                  </SearchContext>
                </ArticleProvider>
              </CategoryProvider>
            </AutherProvider>
          </KBProvider>
        </ToastProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
