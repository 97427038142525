import { useLocation, useNavigate } from 'react-router-dom';

const useNavigateWithSearch = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const navigateWithSearch = (path) => {
    navigate(`${path}${search}`);
  };

  return navigateWithSearch;
};

export default useNavigateWithSearch;