import { getRequest } from "../apiRequest";
import { apiRoutes } from "../Constants/ApiRoutes";

export const getArticles = (id, pathParams) => {
  if (!id) {
    return;
  }
  const url = `${apiRoutes.categories}/${id}/${apiRoutes.getArticlesInCategory}`;
  return getRequest(url, null, pathParams);
};

export const getParticularArticle = (id, pathParams) => {
  if (!id) {
    return;
  }
  const url = `${apiRoutes.getParticulatArticle}/${id}`;
  return getRequest(url, null, pathParams);
};

export const articleSearch = (searchTerm, pathParams) => {
  if (!searchTerm) {
    return;
  }
  const url = `${apiRoutes.getParticulatArticle}/${apiRoutes.articleSearch}`;
  return getRequest(url, null, pathParams);
};
