export const ROLES = {
    ADMIN: "ADMIN",
    MEMBER: "MEMBER",
    VIEWER: "VIEWER",
    UNAUTHORIZED: "UNAUTHORIZED",
};

export const DASHBOARD_ALLOWED_ROLES = [ROLES.ADMIN, ROLES.MEMBER];

export const INTERNAL_KB_ALLOWED_ROLES = [ROLES.ADMIN, ROLES.MEMBER, ROLES.VIEWER];

export const KB_TYPE = {
    INTERNAL: 'internal',
    EXTERNAL: 'external',
}

export const GMAIL = "Gmail";
export const OUTLOOK = "Outlook";
export const OUTLOOK_PRODUCT_TYPE = "hiver-for-outlook";
export const NAVIGATION_BUTTON_TEXT = "Switch to {{product}}";
export const SEARCH_PLACEHOLDER = "Search across Hiver for {{product}}";
