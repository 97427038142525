import { useState, useEffect } from "react";
import { SearchContext } from "../index";
import { articleSearch } from "../../Service/ArticleService";
import { useSearchParams } from "react-router-dom";

export default function ArticleProvider(props) {
  const [searchList, setSearchList] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchParams] = useSearchParams();
  const productParam = searchParams.get('product');

  const handleDebounceSearch = (product) => {
    const pathParams = {
      searchTerm: searchInput,
      ...(product && { product })
    };
    articleSearch(searchInput, pathParams).then((result) => {
      setSearchList(result.data.data.articles);
    });
  };

  useEffect(() => {
    if (searchInput.length === 0) {
      setSearchList(false);
    } else if (searchInput.length > 2) {
      handleDebounceSearch(productParam);
    }
  }, [searchInput, productParam]);

  return (
    <SearchContext.Provider
      value={{
        searchList,
        setSearchInput,
        setSearchList,
        searchInput,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
}
